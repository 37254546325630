import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import angleRight from "../images/common/angle-right-white.svg"
import feather from "../images/home/feather.png"
import profileImg from "../images/coaching/gabriella-coaching.jpg"
import seoContent from "../helpers/seoContent"

const Coaching = () => {
  const { siteDescriptions } = seoContent
  return (
    <Layout>
      <SEO title="Coaching" description={siteDescriptions.default} />
      <div id="coaching">
        <div className="coaching-header">
          <h1>Coaching</h1>
        </div>
        <div className="coaching-body">
          <div className="coaching-content-wrap">
            <div className="coaching-img-container">
              <img src={profileImg} alt="image of Gabriella" />
            </div>
            <div className="coaching-content">
              <div className="coaching-title">Let's Work Together</div>
              <div className="coaching-section">
                <div className="coaching-section-title">Explore Coaching</div>
                <div className="coaching-section-text">
                  <p>
                    Do you want to enhance your self-awareness, develop your
                    leadership ability, and channel your energy towards a
                    greater purpose?
                  </p>
                  <p>
                    Let's work together so you can learn to trust your intuition
                    and maximize your impact in life.
                  </p>
                  <p>
                    I offer coaching for both corporate and non-corporate
                    clients on:
                  </p>
                  <ul>
                    <li>Personal Development</li>
                    <li>Self Awareness</li>
                    <li>Goal-Setting</li>
                    <li>Energy Management</li>
                  </ul>
                  <p>
                    My background includes studying coaching and Organizational
                    Behavior at the London School of Economics, as well as
                    coaching at Accenture. I would be delighted to work with you
                    to create lasting change in your life.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="coaching-follow">
            <div className="footer">
              <div className="learn-more section">
                <div className="title">Learn More</div>
                <div className="content">
                  <a
                    className="learn-btn"
                    href="https://alziari.co/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <span>More About Coaching</span>
                    <img
                      className="arrow"
                      src={angleRight}
                      alt="angle-right-icon"
                    />
                  </a>
                  <a
                    className="learn-btn"
                    href="https://www.schedulicity.com/scheduling/ACJVHN/services"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <span>Book a Session</span>
                    <img
                      className="arrow"
                      src={angleRight}
                      alt="angle-right-icon"
                    />
                  </a>
                </div>
              </div>
              <div className="follow section">
                <div className="title">Follow Her</div>
                <div className="content">
                  <div className="social-media-wrap">
                    <a
                      className="social-media"
                      href="https://www.facebook.com/Gabriella-Alziari-533682247139232/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <i className="fab fa-facebook-f"></i>
                    </a>
                    <a
                      className="social-media"
                      href="https://www.instagram.com/gabriellaalziari/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <i className="fab fa-instagram"></i>
                    </a>
                    <a
                      className="social-media"
                      href="https://www.linkedin.com/in/gabbyalziari/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <i className="fab fa-linkedin-in"></i>
                    </a>
                  </div>
                  <img className="feather" src={feather} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Coaching
